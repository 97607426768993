{
  "outlierCreationExplanation": "Эта аномалия спроса будет исключена при создании прогноза. Изменения вступят в силу после следующей обновления прогноза.",
  "outlierAutoAcceptedTooltip": "Эта аномалия спроса, обнаруженная Flowlity, будет автоматически исключена при создании прогноза спроса",
  "weekAbbreviation": "Н",
  "outlierDetectedByFlowlityCantBeEdited": "Эта аномалия спроса была обнаружена Flowlity и не может быть изменена",
  "editOutlier": "Редактировать аномалию",
  "outlierEventCreationSuccess": "Аномалия спроса успешно создана",
  "outlierEventCreationError": "Произошла ошибка при создании аномалии спроса",
  "outlierEventEditingSuccess": "Аномалия спроса успешно изменена",
  "outlierEventEditingError": "Произошла ошибка при изменении аномалии спроса",
  "date": "Дата",
  "addOutlierWrongTimebucket": "Перейдите в вид \"%{timebucket}\" для добавления аномалии",
  "addOutlierFuturePeriod": "Аномалию можно добавлять только в прошлом",
  "addOutlierAlreadyExists": "Аномалия уже существует для этого периода, вы можете ее изменить вместо создания новой",
  "addOutlier": "Добавить аномалию",
  "default": "По умолчанию",
  "tooltipDefaultTimebucketDropdownDemand": "Эта группировка используется для расчета прогноза",
  "tooltipDefaultTimebucketDropdownPlanning": "Эта группировка используется для расчета плана поставок",
  "format": "Формат",
  "destination": "Место назначения",
  "file": "Файл",
  "download": "Скачать",
  "tableNoun": "Таблица",
  "analytics": "Аналитика",
  "tacticalNoun": "Стратегия",
  "capacity": "Производительность",
  "supplyOrders": "Заказы на поставку",
  "settings": "Настройки",
  "dashboardNoun": "Метрики",
  "demandNoun": "Спрос",
  "planningNoun": "Планирование",
  "whatsNew": "Что нового?",
  "language": "Язык",
  "profileSettings": "Настройки профиля",
  "logout": "Выйти",
  "dimension": "Срез",
  "period": "Период",
  "lastMonth": "Прошлый месяц",
  "lastQuarter": "Последний квартал",
  "last6Months": "Последние 6 месяцев",
  "compareWith": "Сравнить с",
  "lastYear": "Прошлый год",
  "creditsPlural": "Кредитов | Кредит | Кредитов",
  "pinTheSidebar": "Закрепить боковую панель",
  "unpinTheSidebar": "Открепить боковую панель",
  "samePeriodLastYear": "Тот же период в прошлом году",
  "previousPeriod": "Предыдущий период",
  "homeKpiPercentageTooltip": "Разница в процентах между периодами:",
  "selectionIsDisabledWhenSimulationIsValidated": "Выбор отключен при валидации симуляции",
  "selectionIsOnlyAvailableForMtsFlowlityAiProducts": "Выбор доступен только для продуктов MTS Flowlity AI",
  "selected": "{count} выбрано | {count} выбрано | {count} выбрано",
  "itemsSelected": "Выбранные элементы",
  "errorWhileFetchingTagCategories": "Произошла ошибка при получении категорий тегов",
  "tagCategoryUpdateSuccess": "Категория тегов была успешно обновлена",
  "tagCategoryUpdateError": "Произошла ошибка при обновлении категории тегов",
  "tagCategoryDeleteSuccess": "Категория тегов \"{name}\" была успешно удалена",
  "tagCategoryDeleteError": "Произошла ошибка при удалении категории тегов",
  "deleteTagCategory": "Удалить категорию тегов?",
  "deleteTagCategoryButton": "Удалить категорию",
  "tagCategoryCreateSuccess": "Категория тегов была успешно создана",
  "tagCategoryCreateError": "Произошла ошибка при создании категории тегов",
  "categoryName": "Имя категории",
  "optional": "Необязательно",
  "NProductsSelected": "{countDisplay} продукт выбран | {countDisplay} продуктов выбрано",
  "downloadProductsCSVError": "Произошла ошибка при скачивании CSV-файла продуктов.",
  "cannotOpenDetailsWhenSelectingAll": "Вы не можете открыть детали при выборе всех элементов",
  "downloadCSV": "Скачать список в CSV",
  "dueDate": "Дата окончания периода фиксации",
  "fixPeriod": "Зафиксировать период рекомендаций",
  "fixPeriodTitle": "Зафиксировать период для выбранных продуктов?",
  "discardInventoryStrategyChanges": "Отменить изменения стратегии запасов",
  "discardInventoryChangesWarningProductDetail": "Вы потеряете все изменения стратегии запасов, примененные к этому продукту.",
  "inventoryStrategyChangedWarning": "Стратегия запасов изменена.",
  "inventoryStrategyChangedBannerPart1": "Новый план поставок с",
  "incomingChanges": "Входящие изменения",
  "inventoryStrategyChangedBannerPart2": "будет готов после следующего расчета. Вы можете нажать \"Оптимизировать\", чтобы получить новый план поставок немедленно.",
  "inventoryStrategyChangedBannerPart2Planning": "будет готов после следующего расчета. Вы можете нажать \"Оптимизировать\", чтобы получить новый план поставок немедленно.",
  "inventoryStrategyChangedBannerPart2Demand": "будет готов после следующего расчета. Вы можете нажать \"Посмотреть детали\", чтобы проверить детали на странице Планирования.",
  "optimize": "Оптимизировать",
  "seeDetails": "Посмотреть детали",
  "discardInventoryStrategyChangesSuccess": "Ваши изменения в стратегии инвентаризации были отменены.",
  "discardInventoryStrategyChangesError": "Не удалось отменить ваши изменения в стратегии инвентаризации, пожалуйста, попробуйте снова.",
  "productListDiscardInventoryStrategyChangesTitle": "Отменить изменения в стратегии инвентаризации?",
  "productListDiscardInventoryStrategyChangesTooltip": "Отмените изменения в стратегии инвентаризации, которые еще не были учтены при расчете плана поставок.",
  "productsListDiscardInventoryStrategyChangesDescription": "При отмене все изменения, внесенные в выбранные продукты и не примененные к расчету плана поставок, будут утрачены.",
  "discardPeriod": "Отменить фиксацию периода рекомендаций",
  "discardPeriodTitle": "Отменить фиксацию периода рекомендаций для выбранных продуктов?",
  "discardPeriodDescription": "Если вы отмените фиксацию, Flowlity предложит план поставок на всю планируемую горизонт.",
  "discardPeriodConfirmButton": "Отменить",
  "discardPeriodSuccess": "Фиксация периода была отменена",
  "discardPeriodError": "Произошла ошибка при отмене фиксации периода",
  "fixPeriodSuccess": "Период был зафиксирован",
  "fixPeriodError": "Произошла ошибка при фиксации периода",
  "downloadCsv": "Скачать список в CSV",
  "csvExplanation": "Значения, разделённые запятой",
  "downloadTsv": "Скачать список в TSV",
  "tsvExplanation": "Значения, разделённые символом табуляции",
  "noBom": "Этот продукт не является частью какого-либо BOM",
  "deleteOrder": "Удалить заказ",
  "orderDetailsAction": "{action} детали заказа",
  "open": "Открыть",
  "close": "Закрыть",
  "openOrderDetails": "Открыть детали заказа",
  "editInventoryStrategy": "Редактировать стратегию инвентаризации",
  "selectCurrentPage": "Выбрать текущую страницу",
  "selectAllPages": "Выбрать все страницы",
  "clearSelection": "Очистить выделение",
  "units": "единица | единица | единицы",
  "mixed": "смешанный",
  "myStockMaxValue": "Моя максимальная стоимость запаса",
  "searchASupplier": "Поиск поставщика",
  "supplier": "Поставщик",
  "delayCoverage": "Покрытие задержки",
  "categoriesOfTags": "Категории тегов",
  "here": "здесь",
  "useDefaultBufferPolicy": "использовать политику по умолчанию",
  "supplierDelay": "Покрытие задержки",
  "supplierMode": "Значение",
  "ropLevel": "Уровень точки заказа",
  "quantity": "Количество",
  "qty": "Кол-во",
  "coverage": "Покрытие",
  "type": "Тип",
  "time": "Время",
  "stock": "Запас",
  "maxValue": "Максимальное значение",
  "minValue": "Минимальное значение",
  "bufferLevel": "Уровень буфера",
  "bufferLevelTip": "Уровень буфера Flowlity будет влиять на уровень вашего буферного запаса. Чем ниже уровень буфера, тем ниже будет ваш минимальный уровень. Чем выше уровень буфера, тем выше будет ваш минимальный уровень.",
  "back": "Назад",
  "apply": "Применить",
  "select": "Выбрать",
  "strategyType": "Тип стратегии",
  "strategyTypeDesc": "Примените стратегию MTO или MTS для всех продуктов и укажите подробные конфигурации.",
  "supplyBufferPolicyDesc": "Укажите, будет ли политика буфера Время, Запас, Нет или просто используйте конфигурации по умолчанию.",
  "myStockMax": "Максимальный запас",
  "myStockMaxDesc": "Установите максимальное значение запаса для всех продуктов.",
  "whatDoYouWantToEdit": "Что вы хотите отредактировать?",
  "clearAllFilters": "Очистить все",
  "sum": "Сумма",
  "avg": "Среднее",
  "reset": "Сбросить",
  "saveView": "Сохранить вид",
  "enterName": "Введите название",
  "deleteViewTitle": "Вы хотите удалить вид \"{name}\"?",
  "deleteViewDescription": "Если вы удалите этот вид, вы потеряете сохраненные фильтры, настройки столбцов и сортировку",
  "addView": "Добавить новый вид",
  "reorderViews": "Изменить порядок видов",
  "renameView": "Переименовать вид",
  "name": "Имя",
  "duplicate": "Дублировать",
  "rename": "Переименовать",
  "reorder": "Изменить порядок",
  "addANewView": "Добавить новый вид",
  "defaultView": "Вид по умолчанию",
  "discardModalTitle": "Отменить изменения?",
  "openDemand": "Открыть спрос",
  "openPlanning": "Открыть планирование",
  "product": "Продукты | Продукт | Продукты",
  "by": "По",
  "day": "Дни | День | Дни",
  "week": "Недели | Неделя | Недели",
  "month": "Месяцы | Месяц | Месяцы",
  "quarter": "Квартал",
  "year": "Год",
  "save": "Сохранить",
  "customers": "Клиенты",
  "andXMore": "и еще {count}...",
  "noSuppliersFound": "Поставщики не найдены",
  "noAlertsAvailable": "Нет доступных предупреждений",
  "confirm": "Подтвердить",
  "learnMore": "Узнать больше",
  "new": "Новый",
  "days": "дни | день | дни",
  "baseTable": {
    "loadOlderData": "Загрузить старые данные",
    "simulation": "только симуляция",
    "simulationTooltipTitle": "Изменения в твердом предложении",
    "simulationTooltipSubtitle": "Изменения в этой строке не будут сохранены.",
    "simulationTooltipText": "Твердое предложение можно изменить только в вашей ERP."
  },
  "cancel": "Отмена",
  "undo": "Отменить",
  "dateRange": "Диапазон дат",
  "dataJobsAlerts": {
    "error": {
      "subscriptionError": "Произошла ошибка при получении статуса обновления задач данных"
    }
  },
  "backToEditing": "Вернуться к редактированию",
  "delete": "Удалить",
  "deleteOrdersWarningText": "Вы уверены, что хотите удалить выбранные заказы?",
  "deleteWarningCancelButton": "Отмена",
  "deleteWarningConfirmButton": "Да, подтвердить",
  "deleteWarningTitle": "Подтверждение удаления",
  "deliveryDate": "Дата доставки",
  "demand": {
    "chart": {
      "allOrders": "Будущие отгрузки",
      "demandFinalForecast": "Окончательный прогноз",
      "demandForecastFlowlity": "Прогноз Flowlity",
      "demandForecastExternal": "Внешний прогноз",
      "firmOrders": "Подтвержденные отгрузки",
      "plannedOrders": "Планируемые отгрузки",
      "historicalDemand": "Прошлый спрос",
      "confidenceInterval": "Доверительный интервал",
      "maxFlowPred": "Доверительный интервал - макс",
      "minFlowPred": "Доверительный интервал - мин",
      "myforecast": "Мой прогноз",
      "supplierDemand": "Фактическое потребление клиента",
      "title": "Прогнозы"
    }
  },
  "demandTable": {
    "common": {
      "aggregatedView": "Агрегированный вид",
      "clientDemand": "Спрос клиента",
      "finalForecast": "Итоговый прогноз",
      "demandForecastExternal": "Внешний прогноз",
      "finalForecastExpl": "Общий прогноз, учитывающий будущее потребление, мой прогноз и базовый прогноз (Flowlity или внешний)",
      "externalForecast": "Внешний прогноз",
      "externalForecastExpl": "Прогноз или зависимый спрос, предоставленный внешним источником",
      "futureDemand": "Будущий спрос",
      "futureDemandExpl": "Спрос в будущем, который записан как обязательный или запланированный",
      "firmDemand": "Подтвержденный спрос",
      "firmDemandExpl": "Спрос, который подтвержден и согласован для выполнения в будущем",
      "plannedDemand": "Запланированный спрос",
      "plannedDemandExpl": "Спрос, который запланирован для выполнения в будущем",
      "updateError": "Произошла ошибка при сохранении вашего значения",
      "updateSuccess": "Значение успешно сохранено"
    },
    "discardChanges": "Отменить изменения",
    "editingMyForecast": "Редактирование",
    "regularView": {
      "pastDemand": "Прошлый спрос",
      "pastDemandExpl": "Спрос, зафиксированный в прошлом",
      "myForecast": "Мой прогноз",
      "myForecastExpl": "Ручные данные прогноза, влияющие на итоговый прогноз, исходя из базового прогноза (Flowlity или внешнего)",
      "flowlityForecast": "Прогноз Flowlity",
      "flowlityForecastExpl": "Прогноз, созданный Flowlity ИИ"
    },
    "saveUpdateButton": "Сохранить",
    "saving": "Сохранение",
    "supplierView": {
      "allOrders": "Будущие отгрузки",
      "firmOrders": "Подтвержденные отгрузки",
      "plannedOrders": "Запланированные отгрузки",
      "historicalDemand": "Фактическое потребление клиента"
    }
  },
  "deselectAll": "Снять все выделения",
  "discardChanges": "Отменить изменения",
  "discardChangesDescription": "Вы потеряете все внесённые изменения",
  "discardTitle": "Отменить изменения?",
  "discardText": "Вы потеряете все изменения, сделанные во время редактирования",
  "discardCancelButton": "Вернуться к редактированию",
  "discardConfirmButton": "Отменить изменения",
  "productsCount": "0 продуктов | 1 продукт | {count} продуктов",
  "tagsCount": "0 тегов | 1 тег | {count} тегов",
  "dropdown": {
    "alerts": {
      "title": "Предупреждения"
    },
    "attributes": "Атрибуты | Атрибут: {name} | {count} Атрибуты",
    "customers": "Клиенты",
    "dateRange": "Диапазон дат",
    "deselect": "Снять выделение",
    "deselectAll": "Снять все выделения",
    "products": "Продукты",
    "search": "Поиск {suffix}",
    "selectAll": "Выбрать все",
    "subContractedComponents": "Компоненты",
    "suppliers": {
      "title": "Поставщики | Поставщик: {name} | {count} Поставщики",
      "noSuppliersAvailable": "Нет доступных поставщиков"
    },
    "tags": {
      "title": "Теги | Тег: {name} | {count} Теги",
      "counter": "Нет доступных тегов | {count} тег доступен | {count} тегов доступны",
      "cell": {
        "displayTitle": "- | {name} | {count} тегов"
      }
    }
  },
  "emptyData": "Нет данных для отображения",
  "error": {
    "expiredStock": "Произошла ошибка при получении данных о просроченном запасе",
    "storageSites": "Произошла ошибка при получении данных о местах хранения"
  },
  "credits": {
    "available": "Доступных кредитов оптимизации",
    "getMore": "Получить больше кредитов",
    "currentLimit": "В настоящее время у вас доступно {limit} кредитов оптимизации в месяц",
    "needTitle": "Сколько кредитов в месяц вам нужно?",
    "credits/month": "кредиты/месяц",
    "requestWillBeSentExplanation": "Ваш запрос будет отправлен на support{'@'}flowlity.com",
    "cancel": "Отмена",
    "topupSubject": "{companyName}: увеличение кредитов для {siteName}",
    "topupBody": "Пожалуйста, увеличьте доступный месячный лимит до {amount} кредитов/месяц для {siteName} / {companyName} (ID склада: {siteId})",
    "notEnough": "Недостаточно кредитов для выполнения планирования",
    "contactFlowlity": "Получите больше кредитов, связавшись с Flowlity"
  },
  "InputLineCell": {
    "dayMarkedAs": "Этот день отмечен как",
    "empty": "пустой",
    "flowlitySupply": "Это значение рекомендовано Flowlity",
    "supplyIsConfirmed": "Эта поставка подтверждена",
    "areValidated": "подтверждено | подтверждено | подтверждено",
    "fullyFrozen": "полностью заморожено",
    "lotSize": "Размер партии",
    "monthMarkedAs": "Этот месяц отмечен как",
    "moq": "MOQ",
    "originalValue": "Оригинальное значение",
    "partlyFrozen": "частично заморожено",
    "validated": "Внимание, заказ на эту поставку подтвержден",
    "weekMarkedAs": "Эта неделя отмечена как"
  },
  "lastOrderDate": "Крайний срок размещения заказа",
  "lastConfirmedDeliveryDate": "Последняя подтвержденная дата доставки",
  "lastConfirmedDeliveryDateTitle": "Дата доставки",
  "lastConfirmedDeliveryDateSubtitle": "последняя подтвержденная",
  "modal": {
    "backToList": "Вернуться к списку",
    "backToOrder": "Вернуться к заказу",
    "backToCapacityUnit": "Вернуться к единице емкости"
  },
  "no": "Нет",
  "notification": {
    "error": "Ошибка",
    "success": "Успех"
  },
  "dashboard": {
    "actual": "Фактический",
    "daysOfCoverage": "Покрытие запасов",
    "days": "дни",
    "tagDropdownPlaceholder": "Выберите тег, чтобы отфильтровать результаты"
  },
  "planning": {
    "chart": {
      "allOrders": "Заказы",
      "currentDay": "D-1",
      "week": "[W]",
      "weekTooltip": "[Неделя]",
      "Expired": "Просрочено",
      "firmOrders": "Твердые заказы",
      "intervalMax": "Максимум Flowlity",
      "intervalMin": "Минимум Flowlity",
      "Maximum": "Максимум",
      "Minimum": "Минимум",
      "myMinimum": "Мой минимальный запас",
      "reorderPoint": "Точка повторного заказа (ROP)",
      "plannedOrders": "Запланированные заказы",
      "Stock": "Запас",
      "simulatedMin": "Новый минимальный запас",
      "myStockMax": "Мой максимальный запас"
    }
  },
  "planningTable": {
    "aggregatedView": "Агрегированный вид",
    "currentDay": "D-1",
    "restOf": "Остальное",
    "discardChanges": "Отменить изменения",
    "discardCancelButton": "Вернуться к редактированию",
    "discardConfirmButton": "Отменить изменения",
    "discardText": "Вы потеряете все изменения, сделанные во время редактирования",
    "discardTitle": "Отменить изменения?",
    "editingMySupply": "Редактирование",
    "finalForecast": "Окончательный прогноз",
    "justInTime": "У этого продукта отключена генерация плана поставок",
    "leadTime": "дни выполнения",
    "maximum": "Максимум Flowlity",
    "minimum": "Минимум Flowlity",
    "myMinimum": "Мой минимальный запас",
    "reorderPoint": "Точка повторного заказа (ROP)",
    "severalWarningsFound": "Обнаружено {count} проблем с продуктом",
    "noStock": "Для этого продукта не получены данные о запасах. Поэтому запас был установлен на 0",
    "noSupplierWarning": "Этот продукт не имеет привязанного поставщика, пожалуйста, добавьте поставщиков",
    "orders": "Мои поставки",
    "productAdminPage": "страница администратора продукта",
    "recomputeSuppliesInfo": "У нас недостаточно исторических данных по этому продукту для расчета новых рекомендаций по поставкам",
    "recomputeSuppliesSuccess": "Рекомендации по поставкам были пересчитаны, обновите страницу, если хотите увидеть их",
    "saveConfirmButton": "Да",
    "saveTitle": "Применить изменения и обновить заказ(ы)?",
    "saveUpdateButton": "Сохранить",
    "saving": "Сохранение",
    "status3Supplies": "Твердые заказы",
    "plannedOrders": "Запланированные заказы",
    "stock": "Запас",
    "averageFinalForecast": "Покрытие запасов в днях",
    "stockExpired": "Просроченные запасы"
  },
  "products": "Продукты",
  "productSearch": {
    "aboveFlowlityMax": "Выше оптимального уровня запасов",
    "alerts": "Предупреждения",
    "events": "События",
    "belowFlowlityMin": "Ниже оптимального уровня запасов",
    "expiringStocks": "Просроченные запасы",
    "outOfStock": "Нет в наличии",
    "selectedAlerts": "0 предупреждений | 1 предупреждение | {count} предупреждений",
    "selectedEvents": "0 событий | 1 событие | {count} событий",
    "selectedProductsFiltersTags": "0 тегов | 1 тег | {count} тегов",
    "nextNDays": "{count} след. дней",
    "nextNDaysCapitalized": "@.capitalize:productSearch.nextNDays",
    "wholeHorizon": "Без ограничения",
    "daysToAct": "Решить в течение",
    "daysToActExplanation": "Количество дней, оставшихся до решения проблемы. Этот параметр учитывает параметр «время выполнения», поэтму он отражает срочность решения предупреждения. Если существует несколько поставщиков, учитывается среднее время выполнения заказа",
    "toActWithin": "Решить в теч-ие @:productSearch.nextNDays",
    "alertDate": "Дата предупреждения",
    "alertDateExplanation": "Количество дней до возникновения проблемы",
    "alertDuration": "Длительность предупреждения",
    "alertValue": "Значимость предупреждения",
    "stockAlerts": "Предупреждения склада",
    "demandAlerts": "Предупреждения спроса",
    "externalForecastAlerts": "Предупреждения внешнего прогноза"
  },
  "search": "Поиск по имени",
  "selectedSuppliers": "0 поставщиков | 1 поставщик | {count} поставщиков",
  "selectedSubContractedComponents": "0 компонентов | 1 компонент | {count} компонентов",
  "selectedCustomers": "0 клиентов | 1 клиент | {count} клиентов",
  "selectedAttributes": "0 атрибутов | 1 атрибут | {count} атрибутов",
  "site": "Место",
  "siteDropdown": {
    "discardCancelButton": "Вернуться к редактированию",
    "discardConfirmButton": "Отменить изменения",
    "discardText": "Вы потеряете все изменения, сделанные во время редактирования",
    "discardTitle": "Отменить изменения?"
  },
  "suppliers": "Поставщики",
  "table": {
    "cell": {
      "tags": {
        "displayText": "- | {name} | {count} тегов"
      },
      "products": {
        "displayText": "- | {count} | {count}"
      },
      "andMore": "и еще {count}..."
    }
  },
  "updateError": "Не удалось правильно обновить заказ(ы)",
  "updateSuccess": "Заказ(ы) успешно обновлены",
  "yes": "Да",
  "yesterday": "Вчера",
  "today": "Сегодня",
  "loadMore": "Загрузить больше",
  "editing": "Редактирование",
  "loading": "Загрузка...",
  "restoreInitial": "Восстановить начальное",
  "applyToAllPeriods": "Применить ко всем периодам",
  "applyToSpecificPeriods": "Применить к определенным периодам",
  "clearAll": "Очистить все",
  "none": "Нет",
  "toOpenInANewTab": "чтобы открыть в новой вкладке",
  "noEntitiesFound": "Не найдено {entities}",
  "byName": "по имени",
  "byNameAndRef": "по имени и артикулу",
  "nMore": "ещё {count}",
  "overdue": "Просроченные",
  "more": "Больше",
  "euro": "Евро, €",
  "greaterThan": "Больше",
  "between": "Между",
  "lessThan": "Меньше",
  "enter": "Ввод",
  "advancedConfiguration": "Расширенная конфигурация",
  "internalCustomerForecastTooltip": "Прогноз не распространяется на внутренних клиентов. При расчете используется только твёрдый спрос.",
  "familyConstraints": "Ограничения семейства продуктов",
  "familyTagName": "Наименование тега семейства",
  "familyMOQ": "MOQ семейства",
  "familyUnit": "Единство семьи",
  "familyMOQExplanation": "Этот параметр MOQ применяется к группе продуктов, относящихся к этому семейству продуктов",
  "familyLotSize": "Размер партии семейства",
  "familyLotSizeExplanation": "Этот размер партии применяется к \"уровню семейства\" ко всем продуктам, имеющих общий компонент",
  "tags": "Теги | Тег | Теги",
  "tagCategories": "Категории тегов | Категория тега | Категории тегов",
  "usedToGroupOrders": "Группирует заказы",
  "createTagCategory": "Создать категорию тега",
  "addToNewTagCategory": "Добавить в новую категорию тега",
  "editTagCategory": "Редактировать категорию тега",
  "tagsSelected": "{count} тегов выбрано | {count} тег выбран | {count} тегов выбрано",
  "tagCategoryName": "Название категории тега",
  "tagCategoryExplanation1": "Категории тегов - это система для группировки тегов с одинаковым значением для организации и классификации продуктов",
  "tagCategoryExplanation2": "по их атрибутам или характеристикам. Например, у вас могут быть такие категории тегов, как \"Цвет\", \"Размер\" и \"Материал\", каждая из которых может содержать несколько конкретных тегов. Затем вы можете использовать эти категории в списках продуктов для более точного выбора.",
  "seeLess": "Показать меньше",
  "seeMore": "Показать больше",
  "createCategory": "Создать категорию",
  "saveCategory": "Сохранить категорию",
  "add": "Добавить",
  "addToTagCategory": "Добавить в категорию тегов",
  "unassignCategories": "Открепить категории",
  "addToANewCategory": "Добавить в новую категорию",
  "useToGroupOrders": "Использовать для группировки заказов",
  "confirmToAssignNTagsToCategory": "Вы уверены, что хотите добавить этот тег в категорию \"{name}\"? | Вы уверены, что хотите добавить эти {count} тегов в категорию \"{name}\"?",
  "confirmToUnassignNTagsToCategory": "Вы уверены, что хотите открепить этот тег от его категории? | Вы уверены, что хотите открепить эти {count} тегов от их категорий?",
  "unassign": "Открепить",
  "errorAssignmentTags": "Произошла ошибка при присвоении или откреплении тегов от категории",
  "successAssignmentTags": "{count} тегов были добавлены в категорию {name}",
  "successUnassignmentTags": "{count} тегов были откреплены от их категорий",
  "successUseTagsToGroupOrders": "Теги были обновлены",
  "errorUseTagsToGroupOrders": "Произошла ошибка при использовании выбранных тегов для группировки заказов",
  "confirmUseToGroupOrders": "Вы уверены, что хотите использовать {count} выбранных тегов для группировки заказов?",
  "use": "Использовать",
  "errorDeletingTags": "Произошла ошибка при удалении выбранных тегов",
  "successDeletingTags": "Теги были удалены",
  "deleteNTags": "Удалить {count} тегов?",
  "theseTagsUsedInNProducts": "Эти теги используются в {count} продуктах. Это действие нельзя будет отменить.",
  "tooltipProductDefaultSupplier": "При выборе поставщика по умолчанию заказы будут по умолчанию распределяться этому поставщику.",
  "defaultSupplier": "Поставщик по умолчанию",
  "bomFetchError": "Произошла ошибка при получении данных BOM",
  "configureColums": "Настроить столбцы",
  "yourDefaultCompany": "Ваша компания по-умолчанию",
  "yourDefaultSite": "Ваш склад по-умолчанию",
  "tagCategory": "Категория тегов",
  "quotas": {
    "createQuotaDisabledWarning": "Вам не разрешено устанавливать предпочтительного поставщика и создавать новые квоты, когда существуют постоянные квоты. Чтобы получить разрешение на выполнение этих настроек, удалите постоянные квоты",
    "createQuotas": "Создать квоты",
    "defaultSupplierChanged": "Поставщик по умолчанию был обновлен",
    "defaultSupplierIntegratedWarning": "Это значение интегрировано из внешнего источника",
    "defaultSupplierNoAvailable": "Нельзя изменить поставщика по умолчанию, когда у вас есть активные квоты",
    "defaultQuotaExistsWarning": "Вы не можете создавать квоты, когда определен поставщик по умолчанию",
    "deleteQuotaWarningContent": "Если вы удалите эти квоты, вы потеряете созданные настройки. Это действие нельзя будет отменить.",
    "deleteQuotaWarningTitle": "Вы хотите удалить эти квоты",
    "expired": "Истек срок действия",
    "expiredQuotas": "Просроченные квоты",
    "integratedQuotaWarning": "Квоты интегрируются из внешнего источника.",
    "noDefaultSupplier": "Нет поставщика по умолчанию",
    "permanent": "Постоянные квоты",
    "setDefaultSupplier": "Установить поставщика по умолчанию",
    "setDefaultSupplierTooltip": "При выборе предпочтительного поставщика заказы будут автоматически назначены этому поставщику.",
    "setQuotaForSuppliers": "Установить квоты для поставщиков",
    "updateQuotas": "Обновить квоты",
    "form": {
      "create": "Создать квоты",
      "duration": "Длительность",
      "durationRequired": "Пожалуйста, выберите длительность или установите квоты как постоянные",
      "permanentDisabledTooltip": "Вы не можете установить квоты как постоянные, если существуют квоты с длительностью.",
      "rangeAlreadyExists": "Вы не можете выбрать диапазон дат, в котором уже существуют квоты",
      "save": "Сохранить",
      "setAsPermanent": "Установить как постоянные",
      "totalQuotaWarningCurrent": "Сумма текущих квот составляет",
      "totalQuotaWarningExpected": "Сумма значений должна быть равна"
    }
  },
  "productEditTabs": {
    "events": "События",
    "info": "Информация",
    "inventoryStrategies": "Стратегия инвентаризации",
    "quotas": "Квоты",
    "similarProducts": "Похожие продукты",
    "suppliers": "Поставщики",
    "tags": "Теги",
    "bom": "Сборочный чертеж"
  },
  "from": "От",
  "to": "До",
  "selectDate": "Выберите дату",
  "click": "Клик",
  "thereAreOnlySitesWhereProductPresented": "В списке только те склады, где представлен текущий продукт",
  "switchBackTo": "Вернитесь на",
  "toNavigateThroughTheList": "Чтобы использовать навигацию",
  "searchByNameOrPasteRefs": "Найдите по имени или вставьте артикулы",
  "value": "Стоимость",
  "value€": "Стоимость, €",
  "count": "Количество",
  "atNextDelivery": "При следующей доставке",
  "current": "Текущее",
  "last30days": "Последние 30 дней",
  "last3months": "Последние 3 месяца",
  "last6months": "Последние 6 месяцев",
  "last12months": "Последние 12 месяцев",
  "last30d": "Последние 30 дн",
  "last3m": "Последние 3 мес",
  "last6m": "Последние 6 мес",
  "last12m": "Последние 12 мес",
  "next30days": "Следующие 30 дней",
  "next3months": "Следующие 3 месяца",
  "next6months": "Следующие 6 месяцев",
  "next12months": "Следующие 12 месяцев",
  "next30d": "Следующие 30 дн",
  "next3m": "Следующие 3 мес",
  "next6m": "Следующие 6 мес",
  "next12m": "Следующие 12 мес",
  "dataSource": "Источник данных",
  "formula": "Формула",
  "kpiType": "Тип KPI",
  "firmDemand": "Твердый спрос",
  "dataMeasure": "Измерение данных",
  "measure": "Измерение",
  "forecastType": "Тип прогноза",
  "ordersType": "Тип заказов",
  "ordersNoun": "Заказы поставки",
  "min": "Мин",
  "max": "Макс",
  "trend": "Тренд",
  "ratio": "Соотношение",
  "mape": "MAPE",
  "mae": "MAE",
  "fva": "FVA",
  "all": "Все",
  "firm": "Твердый",
  "planned": "Запланированный",
  "statistic": "Статистика",
  "pastPerformance": "Прошлая производительность",
  "finalForecast": "Итоговый прогноз",
  "stockMin": "Мин. запас",
  "zeroStockDays": "Дней без запасов",
  "stockCoverage": "Покрытие запасов",
  "inventoryLevel": "Уровень запасов",
  "average": "Среднее",
  "pastDemand": "Прошлый спрос",
  "flowlityForecastStatisticExplanation": "Набор содержит стоимостные/количественные показатели за определенный период",
  "flowlityForecastPastPerformanceExplanation": "Набор содержит показатели качества прогнозирования: MAPE, MAE, добавленная стоимость прогноза",
  "trendGeneralExplanation": "Тренд сравнивает 2 периода: выбранный и предыдущий за ним. Тренд описывает насколько изменилось значение выбранного периода по отношению к предыдущему периоду в процентах",
  "kpi": "KPI",
  "createKPI": "Создать KPI",
  "thisKpiAlreadyAdded": "Этот KPI уже добавлен",
  "pastDemandValueSumDescription": "Прошлый спрос в € за {for}",
  "pastDemandValueTrendDescription": "Сравнение прошлого спроса (в €) между периодами {period1} и {period2}",
  "pastDemandQtySumDescription": "Прошлый спрос в единицах по умолчанию за {for}",
  "pastDemandQtyTrendDescription": "Сравнение количества прошлого спроса (в единицах по умолчанию) между периодами {period1} и {period2}",
  "daysShort": "д",
  "deleteKPI": "Удалить KPI",
  "deleteKPIMsg": "При удалении этот KPI будет удален из всех представлений и сайтов вашей компании для всех пользователей. Это действие нельзя будет отменить.",
  "kpiDeleted": "KPI удален",
  "allOrders": "Все заказы",
  "firmOrders": "Твердые заказы",
  "plannedOrders": "Запланированные заказы",
  "inventory": "Инвентарь",
  "reference": "Ссылка",
  "nextDelivery": "Следующая доставка",
  "simulated": "Смоделировано",
  "trendGapsLast30d": "[Д-60; Д-31] и [Д-30; Д-1]",
  "trendGapsLast3m": "[М-6; М-4] и [М-3; М-1]",
  "trendGapsLast6m": "[М-12; М-7] и [М-6; М-1]",
  "trendGapsLast12m": "[М-24; М-13] и [М-12; М-1]",
  "trendGapsNext30d": "[Д-30; Д-1] и [Д+1; Д+30]",
  "trendGapsNext3m": "[М-3; М-1] и [М+1; М+3]",
  "trendGapsNext6m": "[М-6; М-1] и [М+1; М+6]",
  "trendGapsNext12m": "[М-12; М-1] и [М+1; М+12]",
  "createKPIDescription": "Вы можете создать KPI, который будет отображаться в виде колонки в списке товаров. Этот KPI будет доступен во всех представлениях и сайтах вашей компании для всех пользователей.",
  "firmDemandQtyTooltip": "Подтвержденные заказы от ваших внешних или внутренних клиентов (заказы на перевод, производственные заказы и т.д.)",
  "finalForecastQtyTooltip": "Окончательный прогноз на {for} в единице по умолчанию",
  "pastDemandExplanation": "Прошлый спрос ({unit}) за {for}{formula}",
  "pastDemandTrendExplanation": "Сравнивает прошлый спрос ({unit}) между периодами {gaps}",
  "firmDemandExplanation": "Подтвержденные заказы ({unit}), полученные от ваших внешних или внутренних клиентов (транспортные заказы, производственные заказы и т.д.) за {for}",
  "finalForecastExplanation": "Окончательный прогноз ({unit}) за {for}",
  "finalForecastTrendExplanation": "Сравнивает окончательный прогноз ({unit}) между периодами {gaps}",
  "externalForecastExplanation": "Внешний прогноз ({unit}) за {for}",
  "externalForecastTrendExplanation": "Сравнивает внешний прогноз ({unit}) между периодами {gaps}",
  "myForecastExplanation": "Мой прогноз ({unit}) за {for}",
  "myForecastTrendExplanation": "Сравнивает мой прогноз ({unit}) между периодами {gaps}",
  "flowlityForecastExplanation": "Прогноз Flowlity ({unit}) за {for}",
  "flowlityForecastTrendExplanation": "Сравнивает прогноз Flowlity ({unit}) между периодами {gaps}",
  "maeExplanation": "Абсолютная ошибка между прогнозом Flowlity и прошлым спросом за последние {count} дней: ABS(спрос - прогноз) в количестве",
  "mapeExplanation": "Средняя абсолютная процентная ошибка между прогнозом Flowlity и прошлым спросом за последние {count} дней: ABS(спрос - прогноз)/спрос (в %)",
  "forecastValueAddedExplanation": "Прогноз Flowlity по сравнению со статистическим прогнозом (скользящее среднее за предыдущие три месяца), когда значение положительное, прогноз Flowlity лучше на X %",
  "ordersCountExplanation": "Количество {dataset} за {for}",
  "ordersValueExplanation": "Сумма значений {dataset} за {for}",
  "ordersQuantityExplanation": "Сумма количеств (в стандартной единице) {dataset} за {for}",
  "inventoryExplanation": "Уровень запасов ({unit}), {for}{formula}",
  "inventoryTrendExplanation": "Сравнивает уровень запасов ({unit}) между периодами {gaps}",
  "stockCoverageExplanation": "Покрытие запасов (в днях), {for}{formula}",
  "stockMinExplanation": "Покрытие запасов ({unit}), {for}{formula}",
  "zeroStockDaysExplanation": "Средний процент дней без запасов за {for}",
  "zeroStockDaysTrendExplanation": "Сравнивает средний процент дней без запасов между периодами {gaps}",
  "minimum": "Минимум",
  "maximum": "Максимум",
  "productDescription": "Описание продукта",
  "leadTime": "Время выполнения заказа",
  "defaultUnitName": "Название стандартной единицы",
  "stockCoverageNext1YAvgTooltip": "Покрытие запасов в днях - среднее за следующий год",
  "stockReduction": "Изменение запаса %",
  "stockChangeTooltip": "Изменение в % уровня запаса по сравнению с базовым уровнем",
  "productName": "Название продукта",
  "defaultUnit": "Стандартная единица",
  "endOfLife": "Конец жизненного цикла",
  "supplyBufferPolicyTooltip": "Стратегия для компенсации неопределенности поставщиков: запасы или временной буфер",
  "reviewDemand": "Пересмотр спроса",
  "reviewPlanning": "Пересмотр планирования",
  "inventoryStrategyNoun": "Стратегия управления запасами",
  "similarProducts": "Похожие продукты",
  "fixingRecommendationsDate": "Фиксированные рекомендации",
  "until": "До",
  "promotions": "Промоакции",
  "demandPredictabilitySubtitle": "спроса",
  "demandPredictabilityTooltip": "Предсказуемость спроса — это качество данных о прошлом спросе, используемых для создания прогноза",
  "demandPredictabilityNoTooltip": "Нет данных о прошлом спросе, невозможно создать прогноз",
  "demandPredictabilityLowTooltip": "Данные о прошлом спросе ограничены и не позволяют создать лучший прогноз",
  "demandPredictabilityMediumTooltip": "Flowlity предоставляет лучший прогноз, однако спрос был непостоянным",
  "demandPredictabilityHighTooltip": "Flowlity предоставляет лучший прогноз",
  "bufferLevelTooltip": "Фактор, влияющий на уровень минимальных запасов: чем выше уровень буфера, тем меньше риска вы готовы принять по продукту, и тем выше минимальный уровень запасов",
  "firmOrdersQtyTooltip": "Количество подтвержденных заказов на поставку",
  "nextDeliveryDate": "Дата следующей поставки",
  "shelfLife": "Срок годности",
  "alerts": "Предупреждения",
  "next1YAvg": "Среднее за след. год",
  "zeroStockDaysNext1YearTooltip": "Средний процент дней без запасов за следующий год",
  "flowlityAi": "Flowlity AI",
  "initialValue": "Исходное значение",
  "tagName": "Имя тега",
  "noTags": "Теги не найдены",
  "nProductsFound": "Найдено 0 продуктов | Найден 1 продукт | Найдено {count} продуктов",
  "nTagsFound": "0 тегов найдено | 1 тег найден | {count} тегов найдено",
  "hasInventoryIncomingChanges": "Стратегия управления запасами изменена",
  "noAttributesFound": "Атрибуты не найдены",
  "continuous": "Непрерывный",
  "demandBasedConstant": "Flowlity AI + Мой минимальный запас",
  "demandBasedFlowlity": "Flowlity AI",
  "demandType": "Тип спроса",
  "demandPredictability": "Предсказуемость спроса",
  "general": "Общее",
  "hasEol": "Имеет конец жизненного цикла",
  "hasForecastEvents": "Есть будущие прогнозные события",
  "hasDecreasingMrp": "Имеет снижение MRP",
  "hasSimilarProducts": "Имеет похожие продукты",
  "hasFixedPeriod": "Имеет зафиксированный период",
  "inventoryStrategyAttr": "Политика инвентаризации",
  "timeBuffer": "Буфер времени",
  "mto": "MTO",
  "mts": "MTS",
  "noEol": "Нет конца жизни",
  "noPolicy": "Без буфера",
  "punctual": "Пунктуальный",
  "reorderPoint": "Точка повторного заказа",
  "reorderPointAI": "Точка повторного заказа + AI",
  "stockBuffer": "Буфер запасов",
  "supplyBufferPolicy": "Политика буфера",
  "withComponents": "С компонентами",
  "punctualConstantFast": "Пунктуальный постоянный быстрый",
  "punctualConstantSlow": "Пунктуальный постоянный медленный",
  "punctualLumpySlow": "Пунктуальный переменный медленный",
  "punctualLumpyFast": "Пунктуальный переменный быстрый",
  "punctualSingleDemand": "Пунктуальный единичный спрос",
  "continuousLumpy": "Непрерывный переменный",
  "continuousSmooth": "Непрерывный гладкий",
  "zero": "Нет или мало данных",
  "less6mData": "Менее 6 месяцев данных",
  "zeroTooltip": "Продукт без прошлого спроса более 1 года",
  "newTooltip": "Продукты с данными менее шести месяцев",
  "punctualConstantFastTooltip": "Пунктуальный продукт с низкой волатильностью спроса и коротким интервалом между двумя спросами в среднем",
  "punctualConstantSlowTooltip": "Пунктуальный продукт с низкой волатильностью спроса и длинным интервалом между двумя спросами в среднем",
  "punctualLumpySlowTooltip": "Пунктуальный продукт с волатильным спросом и длинным интервалом между двумя спросами в среднем",
  "punctualLumpyFastTooltip": "Пунктуальный продукт с волатильным спросом и коротким интервалом между двумя спросами в среднем",
  "punctualSingleDemandTooltip": "Пунктуальный продукт с единственной точкой спроса в прошлом",
  "continuousLumpyTooltip": "Непрерывный продукт, но спрос очень волатильный",
  "continuousSmoothTooltip": "Непрерывный продукт, но спрос не очень волатильный",
  "noDemandPredictability": "Нет",
  "lowDemandPredictability": "Низкая",
  "mediumDemandPredictability": "Средняя",
  "highDemandPredictability": "Высокая",
  "forecastSource": "Источник прогноза",
  "externalForecast": "Внешний прогноз",
  "flowlityForecast": "Прогноз Flowlity",
  "myForecast": "Мой прогноз",
  "createForecastEvent": "Создать новое событие прогноза",
  "editForecastEvent": "Редактировать событие прогноза",
  "eventsCount": "{count} событие | {count} события | {count} событий",
  "assignForecastEvent": "Добавить существующее событие прогноза",
  "quantityShort": "Кол-во",
  "description": "Описание",
  "startDate": "Дата начала",
  "endDate": "Дата окончания",
  "impact": "Влияние",
  "create": "Создать",
  "event": "Событие | События",
  "addEventsTo": "Добавить события в {suffix} | Добавить события к {suffix}",
  "editEvent": "Редактировать событие",
  "deleteEvent": "Удалить событие",
  "future": "Будущее",
  "past": "Прошлое",
  "andMore": "и еще {count}...",
  "forecastEvent": "Событие прогноза | События прогноза",
  "noDescription": "Описание не предоставлено",
  "forecastEventSuccess": "Новое событие прогноза было создано",
  "forecastEventsFetchError": "Произошла ошибка при получении прогнозных событий",
  "forecastEventUpdateSuccess": "Это событие прогноза было обновлено",
  "forecastEventAssignSuccess": "Продукты были назначены выбранным событиям",
  "forecastEventsDeleteTitle": "Удалить прогнозируемое событие?",
  "datePlaceholder": "Выберите дату",
  "deleteWarning": "Вы не сможете отменить это действие",
  "keepEditing": "Продолжить редактирование",
  "cancelModifications": "Отменить изменения",
  "creationDiscardText": "Вы потеряете все изменения, внесенные при создании",
  "editionDiscardText": "Вы потеряете все изменения, внесенные при редактировании",
  "edit": "Редактировать",
  "companyMissMatch": "Только участники этой компании могут выполнить это действие",
  "applyForecastEventFor": "Применить событие для",
  "allCustomers": "Все клиенты",
  "specificCustomers": "Определенные клиенты",
  "specifCustomersInfos": "Обратите внимание, что событие будет добавлено только к продуктам, связанным с указанными клиентами.",
  "futureEvents": "Нет будущих событий | 1 будущее событие | {count} будущих событий",
  "pastEvents": "Нет прошедших событий | 1 прошедшее событие | {count} прошедших событий",
  "cantExceed": "{fieldName} не может превышать {count} символов",
  "minLoadCannotBeGreaterMaxLimit": "Минимальная нагрузка не может превышать предельную. Пожалуйста, исправьте значения, выделенные красным цветом, перед сохранением.",
  "forecastEventExpl": "пользовательские события, определенные пользователем",
  "createNewEventTooltip": "Создать новое событие",
  "selectedCustomersCount": "Выбрано 0 клиентов | Выбран 1 клиент | Выбрано {count} клиентов"
}
