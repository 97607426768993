import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { API, API_V2 } from "@/services/api";
import { API_OR } from "@/services/or";
import { useUserStore } from "@/store/user.store";
import { useAuthStore } from "@/store/auth.store";
import { useSiteStore } from "@/store/site.store";
import { controlRbacAccess } from "@/router/middleware/admin-middleware";
import { app } from "@/main";
import { eventTracking } from "@/utils/event-tracking";
import { ROUTE } from "@/router/enum";
import { useCompanyStore } from "@/store/company.store";
import { usePreferencesStore } from "@/store/preferences.store";
import posthog from "posthog-js";

export const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach(async (to, from, next) => {
  const preferencesStore = usePreferencesStore();
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const companyStore = useCompanyStore();
  const siteStore = useSiteStore();

  if (to.name === ROUTE.CALLBACK) {
    return next();
  } else if (authStore.isAuthenticated) {
    const email = authStore.user?.email ?? "";

    if (import.meta.env.VITE_APM_ACTIVE) {
      // set user email for APM
      app.config.globalProperties.$apm.setUserContext({
        email,
      });
    }

    // once per session
    if (!userStore.currentUser?.id) {
      // set headers after authentication
      API.setHeader();
      API_V2.setHeader();
      API_OR.setHeader();
      await userStore.initMe();
    }

    if (!companyStore.company) {
      const isFlowlityAdmin: boolean = userStore.currentUser.canAccess(["admin:flowlity"]);

      const companyIdToFetch: number = isFlowlityAdmin
        ? (preferencesStore.preferences.lastUsedCompanyId ?? userStore.currentUser.company.id)
        : userStore.currentUser.company.id;

      await companyStore.fetchCompany(companyIdToFetch);
    }

    const siteIdFromUrl: number | undefined = to.params.siteId && (+to.params.siteId) !== -1
      ? +to.params.siteId
      : undefined;

    if (!siteIdFromUrl) {
      const isFlowlityAdmin: boolean = userStore.currentUser.canAccess(["admin:flowlity"]);
      const lastUsedSiteId: number | undefined = preferencesStore.preferences.lastUsedSiteId;

      const siteIdToRedirectTo: number = isFlowlityAdmin
        // Flowlity Admin
        // check if site id from URL exists in the active company
        ? siteIdFromUrl && companyStore.company!.sites.some(s => s.id === siteIdFromUrl)
          // yes -> take site id from URL into account
          ? siteIdFromUrl
          // otherwise -> check last used site from localStorage
          : lastUsedSiteId && companyStore.company!.sites.some(s => s.id === lastUsedSiteId)
            // last used site inside the loaded company -> we select last used site
            ? lastUsedSiteId
            // last used site is not the fetched company -> checking default (assigned to user) site id
            : userStore.currentUser.defaultSite && companyStore.company!.sites.some(s => s.id === userStore.currentUser.defaultSite!.id)
              // default site id is available in the fetched company -> we select default site id
              ? userStore.currentUser.defaultSite.id
              // default site id is not available -> pick 1st site of the company
              : companyStore.company!.sites[0].id
        // Other Roles
        // check if site id from URL exists in the allowed sites list
        : siteIdFromUrl && userStore.currentUser.sites.some(s => s.id === siteIdFromUrl)
          // yes -> take site id from URL into account
          ? siteIdFromUrl
          // otherwise -> check last used site from localStorage
          : lastUsedSiteId && userStore.currentUser.sites.some(s => s.id === lastUsedSiteId)
            // we're able to restore last used site id
            ? lastUsedSiteId
            // we don't have access to last used site anymore:
            // -> fallback to default site if no mismatch with current company
            : (userStore.currentUser.defaultSite && companyStore.company!.sites.some(s => s.id === userStore.currentUser.defaultSite!.id)
                ? userStore.currentUser.defaultSite.id
              // -> or fallback to 1st assigned site or 1st site of the company
                : userStore.currentUser.sites[0].id ?? companyStore.company!.sites[0].id);

      // force-put site id to url if it's empty or -1
      return next({
        name: ROUTE.DASHBOARD,
        params: {
          siteId: siteIdToRedirectTo,
        },
        replace: true,
      });
    }

    // URL is a source of truth for site id
    // we should fetch site id from "to" location before letting the app go to this location
    if (!siteStore.site || siteIdFromUrl !== siteStore.site.id) {
      await siteStore.fetchSite(siteIdFromUrl);

      // trigger posthog identify
      posthog.identify(userStore.currentUser.email, {
        user_id: userStore.currentUser.id,
        company_name: companyStore?.company?.name,
        company_id: companyStore?.company?.id,
        site_name: siteStore.site!.name,
        site_id: siteStore.site!.id,
      });
    }

    const routePermissions = (to.meta?.permissions as string[]) ?? [];

    controlRbacAccess(routePermissions, (toUrl, fromUrl, nextFn) => {
      if (siteStore.site!.isSupplierView && (toUrl.name === ROUTE.DASHBOARD || toUrl.path.includes("planning"))) {
        return nextFn({ name: ROUTE.ORDERS_LIST, params: { siteId: siteIdFromUrl } });
      }

      return nextFn();
    })(to, from, next);
  } else {
    // trigger auth0 login
    authStore.login();
  }
});

router.afterEach(() => {
  // track event : pageview
  // needed because our app is a SPA and hence it doesn't trigger automatically pageview events
  eventTracking("$pageview");
});
