{
  "en": {
    "endOfLife": "Possible end of life",
    "decreasingMrp": "Decreasing MRP",
    "demandOutlier": "Demand Outlier",
    "shortageImpact": "Shortage Impact",
    "promotion": "Promotion",
    "endOfLifeExplanationTitle": "{name} is null from {date}",
    "endOfLifeExplanationDescription1": "If \"accept\" - end of life will be configured on this product, adapting demand & inventory policy",
    "endOfLifeExplanationDescription2": "If \"reject\" - no end of life configured",
    "decreasingMrpExplanationDescription": "Flowlity detected that the MRP is decreasing significantly. Make sure that your inventory strategy is set as My stock min, ROP or MTO and external source for forecast calculation is activated.",
    "demandOutlierExplanation": "Demand is considered abnormal, it is not taken into account and reproduced in the future forecast",
    "shortageImpactExplanationTitle": "Event marking that there was not enough stock to fulfill demand",
    "shortageImpactExplanationDescription1": "If \"accept\" - this period will be considered as shortage and excluded from next forecast calculation",
    "shortageImpactExplanationDescription2": "If \"reject\" - this period will not be considered as shortage",
    "events": "No events | 1 Event | {count} Events",
    "notifications": "Notifications | Notifications (1) | Notifications ({count})",
    "productEvents": "Product Events",
    "autoAccepted": "Auto-accepted",
    "markAsRead": "Mark as read",
    "deleteOutlier": "Delete outlier",
    "deleteShortage": "Delete shortage",
    "reject": "Reject",
    "accept": "Accept",
    "markAsResolved": "Mark as resolved",
    "edit": "Edit",
    "deleteEvent": "Delete event",
    "rejectEvent": "Reject event",
    "editEvent": "Edit event",
    "discardChanges": "Discard changes",
    "save": "Save",
    "editedByUser": "edited by user",
    "name": "Name",
    "description": "Description",
    "date": "Date",
    "startDate": "Start date",
    "endDate": "End date",
    "salesImpact": "Sales impact",
    "products": "Products",
    "noSuggestions": "You’re all good, no suggestions",
    "deleteXEventTitle": "Delete “{name}” event?",
    "deleteXEventDescription": "Do you really want to remove “{name}” event?",
    "rejectXEventTitle": "Reject “{name}” event?",
    "rejectXEventDescription": "Do you really want to reject this “{name}” event? You will not be able to undo this action.",
    "cannotDeleteXEvent": "Cannot delete event “{name}”",
    "cannotRejectXEvent": "Cannot reject event “{name}”",
    "eventXDeleted": "Event “{name}” deleted",
    "eventXRejected": "Event “{name}” rejected",
    "cannotUpdateXEvent": "Cannot update “{name}” event",
    "eventXUpdated": "Event “{name}” updated",
    "discardChangesDescription": "Your will loose all changes that was made",
    "deletePromotionTitle": "Delete {name} event?",
    "deletePromotionDescription": "It will delete this event for all related products",
    "deletePromotionBtn": "Delete event for {count} products",
    "cannotAcceptEventX": "Error occurred while accepting “{name}” event",
    "eventXAccepted": "Possible Event “{name}” accepted",
    "endOfLifeAcceptTitle": "Accept “End of life” event?",
    "endOfLifeAcceptDescription": "End of date will set on {date}. After this date Flowlity forecast & Min level will be set to zero. Forecast baseline will be set to MRP. Those changes will be effective at after the next Flowlity computation.",
    "confirm": "Confirm"
  },
  "fr": {
    "endOfLife": "Fin de vie possible",
    "decreasingMrp": "Diminution de la demande MRP",
    "demandOutlier": "Anomalie de demande",
    "shortageImpact": "Impact de rupture",
    "promotion": "Promotion",
    "endOfLifeExplanationTitle": "Les valeurs de {name} sont nulles à partir du {date}",
    "endOfLifeExplanationDescription1": "Si \"accepter\" - la fin de vie sera configurée sur ce produit, adaptant la demande et la politique de stock",
    "endOfLifeExplanationDescription2": "Si \"rejeter\" - la fin de vie ne sera pas configurée",
    "decreasingMrpExplanationDescription": "Flowlity a détecté que le MRP diminue de manière significative. Assurez-vous que votre stratégie de stock est définie comme Mon stock min, ROP ou MTO et que la source externe pour le calcul des prévisions est activée.",
    "demandOutlierExplanation": "La demande est considérée comme anormale, elle n'est pas prise en compte et reproduite dans les prévisions futures",
    "shortageImpactExplanationTitle": "Événement indiquant qu'il n'y avait pas assez de stock pour répondre à la demande",
    "shortageImpactExplanationDescription1": "Si \"accepter\" - la période sera considérée comme une rupture et exclue du calcul des prochaines prévisions",
    "shortageImpactExplanationDescription2": "Si \"rejeter\" - la période ne sera pas considérée comme une rupture",
    "events": "Aucun événement | 1 événement | {count} événements",
    "notifications": "Notifications | Notifications (1) | Notifications ({count})",
    "productEvents": "Événements de produit",
    "autoAccepted": "Auto-accepté",
    "markAsRead": "Marquer comme lu",
    "deleteOutlier": "Supprimer",
    "deleteShortage": "Supprimer",
    "reject": "Rejeter",
    "accept": "Accepter",
    "markAsResolved": "Marquer comme résolu",
    "edit": "Modifier",
    "deleteEvent": "Supprimer l'événement",
    "rejectEvent": "Rejeter l'événement",
    "editEvent": "Modifier l'événement",
    "discardChanges": "Annuler les modifications",
    "save": "Enregistrer",
    "editedByUser": "modifié par l'utilisateur",
    "name": "Nom",
    "description": "Description",
    "date": "Date",
    "startDate": "Date de début",
    "endDate": "Date de fin",
    "salesImpact": "Impact sur les ventes",
    "products": "Produits",
    "noSuggestions": "Tout va bien, aucune suggestion",
    "deleteXEventTitle": "Supprimer l'événement “{name}”?",
    "deleteXEventDescription": "Voulez-vous vraiment supprimer l'événement “{name}”?",
    "rejectXEventTitle": "Rejeter l'événement “{name}”?",
    "rejectXEventDescription": "Voulez-vous vraiment rejeter cet événement “{name}”? Vous ne pourrez pas annuler cette action.",
    "cannotDeleteXEvent": "Impossible de supprimer l'événement “{name}”",
    "cannotRejectXEvent": "Impossible de rejeter l'événement “{name}”",
    "eventXDeleted": "L'événement “{name}” a été supprimé",
    "eventXRejected": "L'événement “{name}” a été rejeté",
    "cannotUpdateXEvent": "Impossible de mettre à jour l'événement “{name}”",
    "eventXUpdated": "L'événement “{name}” a été mis à jour",
    "discardChangesDescription": "Vous perdrez toutes les modifications apportées",
    "deletePromotionTitle": "Supprimer l'événement {name}?",
    "deletePromotionDescription": "Cet événement sera supprimé pour tous les produits concernés",
    "deletePromotionBtn": "Supprimer pour {count} produits",
    "cannotAcceptEventX": "Une erreur s'est produite lors de l'acceptation de l'événement “{name}”",
    "eventXAccepted": "Événement “{name}” possible accepté",
    "endOfLifeAcceptTitle": "Accepter l'événement “{name}” ?",
    "endOfLifeAcceptDescription": "La date de fin sera fixée au {date}. Après cette date, les prévisions et le niveau minimal de Flowlity seront mis à zéro. La ligne de base des prévisions sera définie sur MRP. Ces changements seront effectifs après le prochain calcul de Flowlity.",
    "confirm": "Confirmer"
  },
  "ru": {
    "endOfLife": "Конец жизненного цикла",
    "decreasingMrp": "Снижение MRP",
    "demandOutlier": "Аномалия спроса",
    "shortageImpact": "Влияние нехватки",
    "promotion": "Промоакция",
    "endOfLifeExplanationTitle": "{name} отсутствует с {date}",
    "endOfLifeExplanationDescription1": "Если \"принять\" - конец жизненного цикла будет настроен для этого продукта, адаптируя политику спроса и запасов",
    "endOfLifeExplanationDescription2": "Если \"отклонить\" - конец жизненного цикла не будет настроен",
    "decreasingMrpExplanationDescription": "Flowlity обнаружил значительное снижение MRP. Убедитесь, что ваша стратегия инвентаризации установлена как Мой минимальный запас, ROP или MTO и внешнее источники для расчета прогноза активированы.",
    "demandOutlierExplanation": "Спрос считается аномальным, он не учитывается и не воспроизводится в будущем прогнозе",
    "shortageImpactExplanationTitle": "Событие, указывающее на нехватку запасов для удовлетворения спроса",
    "shortageImpactExplanationDescription1": "Если \"принять\" - этот период будет считаться нехваткой и исключен из следующего расчета прогноза",
    "shortageImpactExplanationDescription2": "Если \"отклонить\" - этот период не будет считаться нехваткой",
    "events": "Нет событий | 1 Событие | {count} Событий",
    "notifications": "Уведомления | Уведомления (1) | Уведомления ({count})",
    "productEvents": "События продукта",
    "autoAccepted": "Автоматически принято",
    "markAsRead": "Отметить как прочитанное",
    "deleteOutlier": "Удалить аномалию",
    "reject": "Отклонить",
    "accept": "Принять",
    "markAsResolved": "Отметить как решенное",
    "edit": "Редактировать",
    "deleteEvent": "Удалить событие",
    "rejectEvent": "Отклонить событие",
    "editEvent": "Редактировать событие",
    "discardChanges": "Отменить изменения",
    "save": "Сохранить",
    "editedByUser": "редактировано пользователем",
    "name": "Имя",
    "description": "Описание",
    "date": "Дата",
    "startDate": "Дата начала",
    "endDate": "Дата окончания",
    "salesImpact": "Влияние на продажи",
    "products": "Продукты",
    "noSuggestions": "Все в порядке, нет предложений",
    "deleteXEventTitle": "Удалить событие “{name}”?",
    "deleteXEventDescription": "Вы действительно хотите удалить событие “{name}”?",
    "rejectXEventTitle": "Отклонить событие “{name}”?",
    "rejectXEventDescription": "Вы действительно хотите отклонить событие “{name}”? Вы не сможете отменить это действие.",
    "cannotDeleteXEvent": "Не удается удалить событие “{name}”",
    "cannotRejectXEvent": "Не удается отклонить событие “{name}”",
    "eventXDeleted": "Событие “{name}” удалено",
    "eventXRejected": "Событие “{name}” отклонено",
    "cannotUpdateXEvent": "Не удается обновить событие “{name}”",
    "eventXUpdated": "Событие “{name}” обновлено",
    "discardChangesDescription": "Вы потеряете все сделанные изменения",
    "deletePromotionTitle": "Удалить событие {name}?",
    "deletePromotionDescription": "Это удалит это событие для всех связанных продуктов",
    "deletePromotionBtn": "Удалить событие для {count} продуктов",
    "cannotAcceptEventX": "Произошла ошибка при принятии события “{name}”",
    "eventXAccepted": "Возможное событие “{name}” принято",
    "endOfLifeAcceptTitle": "Принять событие “Конец жизненного цикла”?",
    "endOfLifeAcceptDescription": "Дата окончания будет установлена на {date}. После этой даты прогноз Flowlity и минимальный уровень будут установлены на ноль. Базовая линия прогноза будет установлена на MRP. Эти изменения вступят в силу после следующего вычисления Flowlity.",
    "confirm": "Подтвердить"
  }
}
