import { CanceledError } from "axios";
import { stringify } from "qs";

export const REQUEST_TIMEOUT_ERROR_CODE = "ECONNABORTED";

const paramFilterFn = ([, value]: any) => {
  // goal: to clean empty params
  // IN:    undefined     null    ""       []
  // OUT:   false         false   false    false
  return typeof value !== "undefined"
    && value !== null
    && value !== ""
    && !(Array.isArray(value) && value.length === 0);
};

export function apiV1ParamsSerializer(params: any): string {
  return Object.entries(params)
    .filter(paramFilterFn)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        // IN:  array   →  only: ["id", "name"]
        // OUT: string  →  only[]=id&only[]=name
        return value.map(item => `${key}[]=${item}`).join("&");
      } else if (typeof value === "object") {
        return `${key}=${JSON.stringify(value)}`;
      }

      return `${key}=${value}`;
    })
    .join("&");
}

export function filterEmptyStrings(params: any): any {
  return Object.fromEntries(Object.entries(params).filter(([, v]) => typeof v !== "string" || v));
}

export function apiV2ParamsSerializer(params: any): string {
  const paramsWithoutEmptyStrings = filterEmptyStrings(params);
  return stringify(paramsWithoutEmptyStrings, {
    encode: false,
    arrayFormat: "brackets", // 'a[]=b&a[]=c'
    skipNulls: true,
  });
}

export class AbortableRequestHandler<T = any> {
  private abortController: AbortController = new AbortController();

  // if     provided → Promise returns defaultAbortResponse()
  // if not provided → Promise throws CanceledError error
  // it should be a function to have unique reference
  private readonly defaultAbortResponse?: () => T;

  constructor(defaultAbortResponse?: () => T) {
    this.defaultAbortResponse = defaultAbortResponse;
  }

  // this function declines previous request
  // and creates a new AbortController
  // signal of AbortController should be used in your axios request
  declinePreviousAndCreateNew(): AbortSignal {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();
    return this.abortController.signal;
  }

  async handle(request: Promise<T>): Promise<T> {
    try {
      return await request;
    } catch (e: any) {
      if (!(e instanceof CanceledError) || !this.defaultAbortResponse) {
        throw e;
      }

      return this.defaultAbortResponse();
    }
  }
}
