import { createI18n } from "vue-i18n";
import { UserLocaleEnum } from "@/typings/models/v2/user";
import adminTranslations from "./admin";
import BaseComponentsTranslations from "./base.json";
import commentsTranslations from "./comments.json";
import datesTranslations from "./dates.json";
import events from "./events.json";
import forecastReviews from "./forecastReview.json";
import globalTranslations from "./global";
import jobs from "./jobs.json";
import orders from "./orders.json";
import paginationTranslations from "./pagination";
import capacityTranslations from "./capacity";
import tactical from "./tactical.json";
import validationEn from "./validation/en.json";
import validationFr from "./validation/fr.json";
import validateionRu from "./validation/ru.json";
import inventoryStrategy from "@/i18n/inventory-strategy";

export const i18n = createI18n({
  locale: UserLocaleEnum.FR,
  fallbackLocale: UserLocaleEnum.EN,
  legacy: false,
  globalInjection: true,
  allowComposition: true,
  // silentTranslationWarn: import.meta.env.MODE === "production",
  messages: {
    en: {
      ...globalTranslations.en,
      admin: adminTranslations.en,
      base: BaseComponentsTranslations.en,
      capacityUnits: capacityTranslations.en,
      comments: commentsTranslations.en,
      dates: datesTranslations.en,
      events: events.en,
      forecastsReview: forecastReviews.en,
      inventoryStrategy: inventoryStrategy.en,
      jobs: jobs.en,
      orders: orders.en,
      pagination: paginationTranslations.en,
      tactical: tactical.en,
      validation: validationEn,
    },
    fr: {
      ...globalTranslations.fr,
      admin: adminTranslations.fr,
      base: BaseComponentsTranslations.fr,
      capacityUnits: capacityTranslations.fr,
      comments: commentsTranslations.fr,
      dates: datesTranslations.fr,
      events: events.fr,
      forecastsReview: forecastReviews.fr,
      inventoryStrategy: inventoryStrategy.fr,
      jobs: jobs.fr,
      orders: orders.fr,
      pagination: paginationTranslations.fr,
      tactical: tactical.fr,
      validation: validationFr,
    },
    ru: {
      ...globalTranslations.ru,
      admin: adminTranslations.ru,
      base: BaseComponentsTranslations.ru,
      capacityUnits: capacityTranslations.ru,
      comments: commentsTranslations.ru,
      dates: datesTranslations.ru,
      events: events.ru,
      forecastsReview: forecastReviews.ru,
      jobs: jobs.ru,
      orders: orders.ru,
      pagination: paginationTranslations.ru,
      tactical: tactical.ru,
      validation: validateionRu,
    },
  },
});
